exports = module.exports = require("../../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.46.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #00c3ff */ /* #007bc7 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /*top bar color when in dark mode #009CCC*/ /* #00c3ff */ /* #007bc7 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .spinner_container_2gp3A {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n} .spinner_spinner_3_DJ6 {\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n    border: 6px solid;\n    border-color: hsla(289, 100%, 54%, 1) transparent hsla(289, 100%, 54%, 1) transparent;\n    animation: spinner_spinner_3_DJ6 1.2s linear infinite;\n} [theme=\"dark\"] .spinner_spinner_3_DJ6 {\n    border-color: #fff transparent #fff transparent;\n} @keyframes spinner_spinner_3_DJ6 {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n", ""]);

// exports
exports.locals = {
	"container": "spinner_container_2gp3A",
	"spinner": "spinner_spinner_3_DJ6"
};