exports = module.exports = require("../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.46.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".confirm-dialog_base_10eTq {\n    background-color: hsla(194, 100%, 50%, 0.5);\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    z-index: 999999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.confirm-dialog_promptBox_3UiSM {\n    width: 540px;\n    height: 380px;\n    background-color: white;\n    border-radius: 8px;\n    outline-color: hsla(0, 0%, 100%, 0.25);\n    outline-width: 4px;\n    outline-style: solid;\n    overflow: hidden;\n    position: relative;\n    color: black;\n}\n\n.confirm-dialog_header_1YV2X {\n    width: 100%;\n    height: 3.125em;\n    background-color: rgb(86, 5, 161);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n}\n\n.confirm-dialog_buttonRow_1xDb7 {\n    height: 2.125em;\n    position: absolute;\n    right: 0px;\n    bottom: 0px;\n    margin: 16px;\n}\n\n.confirm-dialog_promptButton_tyxXG {\n    margin-left: 0.25rem;\n    margin-right: 0.25rem;\n    padding: 0.75rem 1rem;\n    font-weight: 600;\n    font-size: 0.85rem;\n    border: 0px;\n    border-radius: 4px;\n}\n\n.confirm-dialog_promptButton_tyxXG:focus {\n    outline-color: hsla(271, 88%, 16%, 0.35);\n    outline-width: 4px;\n    outline-style: solid;\n}\n\n.confirm-dialog_accept_3vfBI {\n    background-color: rgb(123, 8, 255);\n    color: white;\n}\n\n.confirm-dialog_deny_10Akd {\n    background-color: white;\n    outline-width: 1px;\n    outline-style: solid;\n    outline-color: rgba(0, 0, 0, 0.15);\n    color: black;\n}\n\n.confirm-dialog_filePicker_hPvJK {\n    border: 0;\n    background-color: hsla(270, 93%, 11%, 0.808);\n    border-radius: 4px;\n    font-weight: 600;\n    color: white;\n    font-size: 0.85rem;\n    padding: 0.75rem 1rem;\n    cursor: pointer;\n}\n\n.confirm-dialog_verticalSlider_2C4MI {\n    transform: rotate(270deg);\n}", ""]);

// exports
exports.locals = {
	"base": "confirm-dialog_base_10eTq",
	"promptBox": "confirm-dialog_promptBox_3UiSM",
	"header": "confirm-dialog_header_1YV2X",
	"buttonRow": "confirm-dialog_buttonRow_1xDb7",
	"promptButton": "confirm-dialog_promptButton_tyxXG",
	"accept": "confirm-dialog_accept_3vfBI",
	"deny": "confirm-dialog_deny_10Akd",
	"filePicker": "confirm-dialog_filePicker_hPvJK",
	"verticalSlider": "confirm-dialog_verticalSlider_2C4MI"
};